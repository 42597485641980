'use strict';

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart .js-flyout').removeClass('show')
        .trigger('flyout.hidden');
    $('.minicart').trigger('count:update', response);

    if (response.error && response.details) {
        // TODO - mixed bag error here
        console.log("mixed bag - cannot add to cart");
        return;
    }

    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        $('body').trigger('cart:update');
        $('.js-minicart-main .minicart-link').trigger('click');

        setTimeout(function () {
            $('.minicart .js-flyout').removeClass('show', 1000, 'easeInBack')
                .trigger('flyout.hidden');
        }, 5000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

function addToCartFromPLP(response) {
    var target = response.container.find('.size-attribute .selected').parent().attr('data-pid', response.data.product.id).addClass('add-to-cart-tile');
    var addToCartUrl;
    var pid;

    $('body').trigger('product:beforeAddToCart', target);

    $(this).find('.swatch-square').addClass('selected');

    pid = target.data('pid');

    addToCartUrl = target.data('addtocarturl');

    var form = {
        pid: pid,
        childProducts: getChildProducts(),
        quantity: '1',
        cartAddType: 'Quick Add'
    };

    target.trigger('updateAddToCartFormData', form);

    if (addToCartUrl) {
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: form,
            success: function (data) {
                handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

module.exports = {

    tileImages: function () {
        $(document).on('mouseenter', '.product-tile .tile-image, .js-tile-image', function () {
            var imageUrl = $(this).attr('data-hover-url');
            var $image = $(this).closest('.tile-image, .js-tile-image');
            if (imageUrl) {
                $image.attr('src', imageUrl);
            }
            var $desktop = $image.siblings('.js-tile-desktop-image');
            var imageUrlDesktop = $desktop.attr('data-hover-url');
            if ($desktop.length && imageUrlDesktop) {
                $desktop.attr('srcset', imageUrlDesktop);
            }
        }).on('mouseleave', '.product-tile .tile-image, .js-tile-image', function () {
            var imageUrl = $(this).attr('data-url');
            var $image = $(this).closest('.tile-image, .js-tile-image');
            if (imageUrl) {
                $image.attr('src', imageUrl);
            }
            var $desktop = $image.siblings('.js-tile-desktop-image');
            var imageUrlDesktop = $desktop.attr('data-url');
            if ($desktop.length && imageUrlDesktop) {
                $desktop.attr('srcset', imageUrlDesktop);
            }
        });
    },

    tileSwatches: function () {
        $(document).on('click', '.tile-body .color-swatch', function () {
            $(this).closest('.tile-body').find('.selected').removeClass('selected');
            var swatchUrl = $(this).data('image-url');
            var imageHoverUrl = $(this).data('hover-url');
            var productUrl = $(this).data('url');
            var $image = $(this).closest('.product-tile').find('.tile-image');
            if (swatchUrl) {
                $image.attr('data-hover-url', imageHoverUrl).attr('src', swatchUrl).attr('data-url', swatchUrl);
                $image.parent().attr('href', productUrl);
            }
            $(this).find('.swatch-value').addClass('selected');
        });
    },

    tileSwatchCount: function () {
        $('.color-swatches').each(function () {
            var total = 0;
            var $moreSwatches = $(this).find('.more-swatches');
            $(this).find('.color-swatch').each(function () {
                total += $(this).length;
            });
            var shownItems = 3;
            if ($(window).width() <= 768 && total > 3 || $(window).width() >= 768 && total > 5) {
                if ($(window).width() >= 768) {
                    shownItems = 5;
                }
                $moreSwatches.removeClass('d-none').addClass('more-shown').empty().append('+' + (total - shownItems) + '&nbsp;More');
             }
        });
    },

    selectSwatch: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if (response.container.hasClass('product-quickview') && response.container.find('.size-attribute').length >= 1) {
                if (response.data.product.readyToOrder) {
                    addToCartFromPLP(response);
                }
            }
        });
    }
};
