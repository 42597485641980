'use strict';
module.exports = function () {
    var sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

    sizes.forEach(function (size) {
        var selector = '.collapsible-' + size + ' .title';
        $('body').on('click', selector, function (e) {
            e.preventDefault();
            
            var $accordion = $(this).parents('.collapsible-' + size);
            $accordion.toggleClass('active');
            
            $('.refinement').not($accordion).removeClass('active');

            if ($accordion.hasClass('active')) {
                $(this).attr('aria-expanded', true);
                $(this).trigger('collapsible.open');

                if ($accordion.hasClass('collapsible-exclusive')) {
                    $accordion.siblings('.collapsible-exclusive.active').find('.title').trigger('click');
                }
            } else {
                $(this).attr('aria-expanded', false);
                $(this).trigger('collapsible.closed');
            }
        });
    });
};
